@use '@angular/material' as mat;
@mixin matero-reboot-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);

  a {
    color: mat.get-color-from-palette($primary);

    &:hover {
      color: lighten(mat.get-color-from-palette($primary), 10%);
    }
  }

  code {
    background-color: rgba(mat.get-color-from-palette($foreground, secondary-text), .03);
  }
}
