@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin matero-error-code-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .matero-error-code {
    color: mat.get-color-from-palette($foreground, text);
  }
}
